import React, {useCallback} from 'react';
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import {useDispatch, useSelector} from 'react-redux';
import {updateNodeData} from '@stackhouseos/flower-client';
import {actionUpdate} from '@stackhouseos/flower-client';
import {getCartTotal} from '../../containers/Cart/selectors';
import Loading from '../Loading';
import {getNameAccount} from '../../containers/App/selectors';

const PaypalButtonList = ({flowName, reducerName}) => {
  const dispatch = useDispatch();
  const [{isPending}] = usePayPalScriptReducer();
  const total = useSelector(getCartTotal);
  const name = useSelector(getNameAccount);

  const onCapture = useCallback(
    data => {
      dispatch(actionUpdate('cart', 'paymentSession', data));
      dispatch(updateNodeData(flowName, 'success', true));
      dispatch({type: 'flower/next', payload: {flowName, reducerName}});
    },
    [dispatch, flowName, reducerName],
  );

  const onErrorCapture = useCallback(
    err => {
      console.log('TCL: onErrorCapture -> err', err);
      dispatch(
        actionUpdate(
          'cart',
          'paymentSessionError',
          err.message || 'errore generico',
        ),
      );
      dispatch(updateNodeData(flowName, 'error', 'error-payment'));
      dispatch({type: 'flower/next', payload: {flowName, reducerName}});
    },
    [dispatch, flowName, reducerName],
  );

  return (
    <>
      {isPending && <Loading />}
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            application_context: {
              shipping_preference: 'NO_SHIPPING',
            },
            purchase_units: [
              {
                description: name || '',
                amount: {
                  value: total,
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          console.log('TCL: PaypalButtonList -> onApprove', data);
          return actions.order.capture().then(onCapture).catch(onErrorCapture);
        }}
        onCancel={data => {
          console.log('TCL: PaypalButtonList -> onCancel', data);
          // Show a cancel page, or return to cart
        }}
      />
    </>
  );
};

export default function PaypalButton({flowName, reducerName}) {
  return (
    <PayPalScriptProvider
      options={{
        'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: 'EUR',
        'data-locale': 'IT',
        'disable-funding': 'sofort,mybank',
      }}>
      <PaypalButtonList flowName={flowName} reducerName={reducerName} />
    </PayPalScriptProvider>
  );
}
