import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {actionUpdate} from '@stackhouseos/flower-client';

function CartTrash({flowName}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionUpdate('cart', 'items', []));
    dispatch({type: 'flower/next', payload: {name: flowName}});
  }, [dispatch, flowName]);

  return null;
}

export default React.memo(CartTrash);
