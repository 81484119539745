import React from 'react';
import {View, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  xxsmall: {
    height: 5,
    width: 5,
  },
  xsmall: {
    height: 10,
    width: 10,
  },
  small: {
    height: 20,
    width: 20,
  },
  halfFlex: {
    flex: 0.5,
  },
  expanded: {
    flex: 1,
  },
  medium: {
    height: 40,
    width: 40,
  },
  large: {
    height: 60,
    width: 60,
  },
  xlarge: {
    height: 80,
    width: 80,
  },
  xxlarge: {
    height: 100,
    width: 100,
  },
});

function Spacer({size = 'small', customSize}) {
  return (
    <View
      style={[
        styles[size],
        customSize && {
          width: parseInt(customSize),
          height: parseInt(customSize),
        },
      ]}
    />
  );
}

export default React.memo(Spacer);
