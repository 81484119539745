import React from 'react';
import {View, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  main: {
    alignItems: 'stretch',
  },
  flexOne: {
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
  },
  border: {
    borderColor: '#ccc',
    borderWidth: 1,
    margin: 5,
    borderRadius: 10,
  },
  white: {
    backgroundColor: '#FFF',
  },
  background: {
    backgroundColor: '#ccc',
  },
});

export default function Myview({
  children,
  gutterBottom,
  paddingHorizontal,
  paddingVertical,
  style,
  background,
  justify,
  align,
  row,
  border,
  flexOne,
}) {
  return (
    <View
      style={[
        styles.main,
        paddingHorizontal && {paddingHorizontal: 20},
        paddingVertical && {paddingVertical: 20},
        gutterBottom && {marginBottom: 15},
        row && styles.row,
        background && styles[background],
        border && styles.border,
        flexOne && styles.flexOne,
        justify && {justifyContent: justify},
        align && {alignItems: align},
        style,
      ]}>
      {children}
    </View>
  );
}
