import React from 'react';
import {TouchableOpacity, StyleSheet} from 'react-native';
import Icon from '../Icon';
import HocActionPress from '../HocActionPress';
import {noop} from 'lodash';

const styles = StyleSheet.create({
  main: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function IconButton({
  icon,
  onPress,
  onLongPress,
  size = 44,
  style,
  activeOpacity,
  disabled,
  color = 'white',
}) {
  return (
    <TouchableOpacity
      onPress={disabled ? noop : onPress}
      onLongPress={onLongPress}
      activeOpacity={disabled ? 0.5 : activeOpacity}
      style={[styles.main, style]}>
      <Icon name={icon} size={size / 1.3} color={color} style={style} />
    </TouchableOpacity>
  );
}

export default React.memo(HocActionPress(IconButton));

IconButton.defaultProps = {
  activeOpacity: 0.8,
};
