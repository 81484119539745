import {combineReducers} from '@reduxjs/toolkit';
import {flowerReducer} from '@stackhouseos/flower-client';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import AsyncStorage from '@react-native-community/async-storage';
import {persistReducer} from 'redux-persist';

import app from '../containers/App/reducer';
import products from '../containers/Products/reducer';
import categories from '../containers/Categories/reducer';
import cart from '../containers/Cart/reducer';

const cartPersistConfig = {
  key: 'cart',
  storage: AsyncStorage,
  whitelist: ['items'],
  stateReconciler: autoMergeLevel2,
};

const appPersistConfig = {
  key: 'app',
  storage: AsyncStorage,
  whitelist: ['info'],
  stateReconciler: autoMergeLevel2,
};

export default combineReducers({
  products,
  categories,
  app: persistReducer(appPersistConfig, app),
  cart: persistReducer(cartPersistConfig, cart),
  flower: flowerReducer,
});
