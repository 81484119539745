import React, {forwardRef, Fragment, useMemo} from 'react';
import {Router} from '@reach/router';
import {StyleSheet, View} from 'react-native';

const styles = StyleSheet.create({
  main: {
    flex: 1,
  },
  routeWrapper: {
    flex: 1,
  },
});

const Layout = forwardRef((props, ref) => (
  <Fragment>{props.children}</Fragment>
));

const RouteWrapper = ({component}) => {
  return <View style={styles.routeWrapper}>{component}</View>;
};

const RouterComponent = ({children}) => {
  const routes = useMemo(() => {
    return React.Children.map(children, child => {
      return (
        <RouteWrapper
          key={child.key}
          default={child.props.child.path === 'default'}
          path={child.props.child.path}
          component={child}
        />
      );
    });
  }, [children]);

  return <Router component={Layout}>{routes}</Router>;
};

export default React.memo(RouterComponent);
