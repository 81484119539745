import React from 'react';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

// Generate required css
import iconFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: MaterialCommunityIcons;
}`;

// Create stylesheet
const style = document.createElement('style');
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);

export default ({name, size = 24, color}) => (
  <Icon name={name} size={parseInt(size)} color={color || '#FFF'} />
);
