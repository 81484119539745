import React from 'react';
import {TouchableOpacity, StyleSheet, View} from 'react-native';

import HocActionPress from '../HocActionPress';

import Text from '../Text';
import Icon from '../Icon';

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 50,
    paddingHorizontal: 10,
    borderRadius: 8,
    marginVertical: 0,
    justifyContent: 'center',
  },
  primary: {
    backgroundColor: '#FAB401',
    borderColor: '#FAB401',
  },
  white: {
    backgroundColor: '#fff',
    borderColor: '#fff',
  },
  outline: {
    borderWidth: 1,
    backgroundColor: 'transparent',
  },
  noBackground: {
    paddingVertical: 5,
    paddingHorizontal: 5,
    minHeight: 'auto',
    backgroundColor: 'transparent',
  },
  noBorders: {
    borderRadius: 0,
    paddingVertical: 28,
    paddingHorizontal: 0,
  },
  small: {width: '30%'},
  medium: {width: '70%'},
  full: {width: '100%'},
  flex: {flex: 1},
  flatlist: {
    backgroundColor: 'white',
    borderRadius: 0,
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderColor: '#efefef',
  },
  disabled: {
    backgroundColor: '#D0DCDC',
    opacity: 0.8,
  },
  disableRightRadius: {
    borderTopEndRadius: 0,
    borderBottomEndRadius: 0,
  },
  disableLeftRadius: {
    borderTopStartRadius: 0,
    borderBottomStartRadius: 0,
  },
});

const stylesText = StyleSheet.create({
  text: {
    fontSize: 18,
  },
  white: {
    color: 'white',
  },
  primary: {
    color: '#FAB401',
  },
  flatlistButton: {},
});

const iconColors = {
  primary: '#FAB401',
};

function MyButton({
  icon,
  bold,
  size,
  title,
  height,
  onBlur,
  onPress,
  variant,
  justify,
  iconLeft,
  disabled,
  textColor,
  iconColor,
  buttonType,
  right_container,
  disableRightRadius,
  disableLeftRadius,
}) {
  return (
    <TouchableOpacity
      disabled={disabled}
      onBlur={onBlur}
      activeOpacity={disabled ? 1 : 0.5}
      style={[
        styles.button,
        styles[variant],
        size && styles[size],
        disabled && styles.disabled,
        height && {minHeight: parseInt(height)},
        buttonType && styles[buttonType],
        justify && {justifyContent: justify},
        disableLeftRadius && styles.disableLeftRadius,
        disableRightRadius && styles.disableRightRadius,
      ]}
      onPress={onPress}>
      {iconLeft && (
        <Icon color={iconColors[iconColor] || '#000'} name={iconLeft} />
      )}
      {title ? (
        <Text
          style={[
            styles.text,
            stylesText[textColor],
            bold && {fontWeight: '600'},
          ]}>
          {title}
        </Text>
      ) : null}

      {icon ? (
        <Icon color={iconColors[iconColor] || '#000'} name={icon} />
      ) : null}
      {right_container || null}
    </TouchableOpacity>
  );
}

export default React.memo(HocActionPress(MyButton));
