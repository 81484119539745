import React, {useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import {getCartProduct} from '../../containers/Cart/selectors';

import Text from '../Text';
import Image from '../Image';
import IconButton from '../IconButton';
import Spacer from '../Spacer';
import {actions} from '../../containers/Cart/reducer';

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderColor: '#EFEFEF',
    borderBottomWidth: 1,
    justifyContent: 'space-between',
    padding: 15,
  },
  info: {
    marginLeft: 5,
    flex: 1,
    flexWrap: 'wrap',
  },
  text: {
    flex: 1,
    fontSize: 13,
    flexWrap: 'wrap',
    marginVertical: 5,
  },
  rightContent: {
    alignItems: 'flex-end',
  },
  buttonBox: {
    marginVertical: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  qty: {
    alignSelf: 'flex-end',
    textAlign: 'center',
  },
});

function CartItem({value}) {
  const dispatch = useDispatch();

  const product = useSelector(getCartProduct(value));

  const handleIncreaseProduct = useCallback(() => {
    dispatch(actions.increment({value: product._id}));
  }, [dispatch, product]);

  const handleDecreaseProduct = useCallback(() => {
    dispatch(actions.decrement({value: product._id}));
  }, [dispatch, product]);

  return (
    <View style={styles.main}>
      <Image width="80" height="80" url={product.image} />
      <View style={styles.info}>
        <Text bold style={styles.text}>
          {product.name}
        </Text>
        <Text format="price" style={styles.text}>
          {product.price * product.qty}
        </Text>
      </View>
      <View>
        <Text style={styles.qty} text={`Qty: ${product.qty}`} />
        <View style={styles.buttonBox}>
          <IconButton
            icon="plus"
            color="#000"
            activeOpacity={0.2}
            style={styles.button}
            onPress={() => handleIncreaseProduct()}
          />
          <Spacer size="xxsmall" />
          <IconButton
            icon="minus"
            color="#000"
            activeOpacity={0.2}
            style={styles.button}
            onPress={() => handleDecreaseProduct()}
          />
        </View>
      </View>
    </View>
  );
}

export default React.memo(CartItem);
