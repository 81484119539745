import React, {useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from '@reach/router';

import {FlowerContext} from '@stackhouseos/flower-client';
import {Alert} from 'react-native';
import {Platform} from 'react-native';
import {composePath} from '../../utils';

export default WrappedComponent => {
  const HocComponent = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {flowName, reducerName} = useContext(FlowerContext);

    const {
      stepId,
      actionType,
      customAction,
      onPress,
      confirm,
      valueOnPress: value,
      route,
    } = props;

    const onPressFlow = useCallback(() => {
      const flow = flowName;

      if (!actionType) {
        return;
      }

      if (actionType === 'pop') {
        return window.history.back();
      }

      if (actionType === 'navigate') {
        return navigate(composePath(route, {id: value}));
      }

      dispatch({
        type:
          actionType === 'custom' && customAction ? customAction : actionType,
        payload: {
          flowName: flow,
          reducerName: reducerName,
          step: stepId,
          value,
        },
      });
    }, [
      dispatch,
      flowName,
      reducerName,
      stepId,
      actionType,
      customAction,
      value,
      navigate,
      route,
    ]);

    const onPressHaptic = useCallback(() => {
      if (onPress) {
        return onPress();
      }
      onPressFlow();
    }, [onPress, onPressFlow]);

    const onConfirm = () => {
      if (Platform.OS === 'web') {
        // eslint-disable-next-line no-alert
        if (window && window.confirm(confirm)) {
          onPressHaptic();
        }
        return;
      }

      Alert.alert(
        'Attenzione',
        confirm,
        [
          {
            text: 'Annulla',
            style: 'cancel',
          },
          {
            text: 'Conferma',
            onPress: onPressHaptic,
          },
        ],
        {cancelable: false},
      );
    };

    return (
      <WrappedComponent
        {...props}
        onPress={confirm ? onConfirm : onPressHaptic}
      />
    );
  };

  return HocComponent;
};
