import axios from 'axios';
import {put, takeLatest, takeEvery} from 'redux-saga/effects';
import {actionUpdate} from '@stackhouseos/flower-client';
import {updateNodeData} from '@stackhouseos/flower-client';
import sortBy from 'lodash/sortBy';

function* appInit({payload}) {
  const {flowName, reducerName} = payload;
  const [slug] = /* ['localhost:3000']; */ window.location.host.split('.');
  const skipMaintenance = window.location.pathname === '/skip';

  try {
    const account = yield axios.get('/api/account/' + slug);
    yield put(actionUpdate('app', 'account', account.data));
    yield put(actionUpdate('app', 'skip', skipMaintenance));

    const {sortedData} = account.data;

    const categories = yield axios.get('/api/categories/' + account.data.id);
    const categoriesSorted = sortBy(categories.data, item =>
      sortedData.categories.indexOf(item._id.toString()),
    );
    yield put(actionUpdate('categories', 'items', categoriesSorted));

    const products = yield axios.get('/api/products/' + account.data.id);
    yield put(actionUpdate('products', 'items', products.data));

    yield put(updateNodeData(flowName, 'success', true));
  } catch (error) {
    console.log('TCL: function*appInit -> error', error);
    yield put(updateNodeData(flowName, 'error', error));
  } finally {
    yield put({type: 'flower/next', payload: {flowName, reducerName}});
  }
}

function* start({payload}) {
  const {flowName, reducerName} = payload;
  yield put({type: 'flower/next', payload: {flowName, reducerName}});
}

function* checkMaintenance({payload}) {
  const {flowName, reducerName} = payload;
  const [slug] = window.location.host.split('.');

  const res = yield axios.get('/api/account/check-maintenance?slug=' + slug);
  yield put(updateNodeData(flowName, 'success', res.data.maintenance));
  yield put({type: 'flower/next', payload: {flowName, reducerName}});
}

export default function* homeSaga() {
  yield takeLatest('START', start);
  yield takeLatest('CHECK_MAINTENANCE', checkMaintenance);
  yield takeEvery('APP_INIT', appInit);
}
