import React, {useMemo} from 'react';
import Flow from '@stackhouseos/flower-client';
import {keyBy} from 'lodash';
import Text from '../Text';

const context = require.context('../../', true, /[\s\S]*?.flower.json$/);

const flowers = context
  .keys()
  .map(filename => context(filename))
  .filter(e => e.type === 'flow');

const flow = keyBy(flowers, 'name');

export default React.memo(function UseFlow(props) {
  const {source, flowName, subReducerName, subFlowName, extraParams, startId} =
    props;

  const extraContext = useMemo(
    () => ({
      parentFlow: flowName,
      subflow: (extraParams || []).reduce(
        (acc, inc) => ({...acc, [inc.name]: inc.value}),
        {},
      ),
    }),
    [flowName, extraParams],
  );

  return flow[source] ? (
    <Flow
      destroyOnUnmount
      parentFlow={flowName}
      elements={flow[source || subFlowName].elements}
      name={subFlowName}
      extraContext={extraContext}
      startId={startId}
      reducerName={subReducerName}
    />
  ) : (
    <Text text="NO_FLOW" />
  );
});
