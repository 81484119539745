import {put, select, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import {actions} from './reducer';
import {getProductsItemsIds} from '../Products/selectors';
import {actionUpdate} from '@stackhouseos/flower-client';
import {updateNodeData} from '@stackhouseos/flower-client';
import {sectorConfig} from '../../config';
import _get from 'lodash/get';

function* addToCart({payload}) {
  const products = yield select(getProductsItemsIds);
  const product = products[payload.value];
  yield put(actions.add({product}));
}

function* selectSector() {
  const {info} = yield select(state => state.app);
  const {area = '', color = ''} = info;

  const getLines = _get(sectorConfig, `${color}.${area}.lines`, '');
  const getSeats = _get(sectorConfig, `${color}.${area}.seats`, '');
  const getSectorName = _get(sectorConfig, `${color}.${area}.name`, '');

  yield put(actionUpdate('app', 'options.lines', getLines));
  yield put(actionUpdate('app', 'options.seats', getSeats));
  yield put(actionUpdate('app', 'info.sectorName', getSectorName));
  yield put(actionUpdate('app', 'info.sectorCode', color + area));
  yield put(actionUpdate('app', 'info.seat', ''));
  yield put(actionUpdate('app', 'info.line', ''));
}

function* loadSector() {
  const {info} = yield select(state => state.app);
  const {area = '', color = ''} = info;

  const getLines = _get(sectorConfig, `${color}.${area}.lines`, '');
  const getSeats = _get(sectorConfig, `${color}.${area}.seats`, '');

  yield put(actionUpdate('app', 'options.lines', getLines));
  yield put(actionUpdate('app', 'options.seats', getSeats));
}

function* paymentProcess({payload}) {
  const {flowName, reducerName} = payload;

  const items = yield select(state => state.cart.items);
  const {info, account} = yield select(state => state.app);
  const {id: accountId} = account;
  const {
    name = '',
    area = '',
    line = '',
    seat = '',
    color = '',
    sectorName = '',
  } = info || {};

  let err;
  let data;

  try {
    const response = yield axios.post('/api/order', {
      accountId,
      name,
      code: `${sectorName} (${color}${area})\nFila: ${line} Posto: ${seat}`,
      items: items.map(rec => ({id: rec.dishId, qty: rec.qty})),
    });
    data = response.data;
    console.log('response', response);
    yield put(updateNodeData('cart', 'success', true));
  } catch (error) {
    console.log('TCL: function*paymentProcess -> error', error);
    err = error;
    console.log('error', error);
    yield put(updateNodeData('cart', 'error', error.message));
  } finally {
    if (!err) {
      window.location.href = data.url;
    } else {
      yield put({type: 'flower/next', payload: {flowName, reducerName}});
    }
  }
}

function* orderCreate({payload}) {
  const {flowName, reducerName} = payload;

  const {id, payer} = yield select(state => state.cart.paymentSession);
  const items = yield select(state => state.cart.items);
  const {info, account} = yield select(state => state.app);
  const {id: accountId} = account;
  const {
    phone = '',
    name = '',
    area = '',
    line = '',
    seat = '',
    color = '',
    sectorName = '',
  } = info || {};

  try {
    yield axios.post('/api/order-create-temp', {
      tempId: id,
      email: payer.email_address,
      accountId,
      name,
      phone,
      code: `${sectorName} (${color}${area})\nFila: ${line} Posto: ${seat}`,
      items: items.map(rec => ({id: rec.dishId, qty: rec.qty})),
    });
    yield put(updateNodeData('cart', 'success', true));
  } catch (error) {
    console.log('error', error);
    yield put(updateNodeData('cart', 'error', error.message));
  } finally {
    yield put({type: 'flower/next', payload: {flowName, reducerName}});
  }
}

export default function* homeSaga() {
  yield takeLatest('CART_ADD', addToCart);
  yield takeLatest('LOAD_SECTOR', loadSector);
  yield takeLatest('SELECT_SECTOR', selectSector);
  yield takeLatest('PAYMENT_PROCESS', paymentProcess);
  yield takeLatest('CREATE_ORDER_TEMP', orderCreate);
}
