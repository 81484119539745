import React from 'react';
import {TouchableOpacity, StyleSheet} from 'react-native';

import HocActionPress from '../HocActionPress';

const styles = StyleSheet.create({
  button: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    borderRadius: 8,
    marginHorizontal: 10,
    marginVertical: 7,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
});

function BigButton({children, onPress}) {
  return (
    <TouchableOpacity style={[styles.button]} onPress={onPress}>
      {children}
    </TouchableOpacity>
  );
}

export default React.memo(HocActionPress(BigButton));
