import React, {useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import {useDispatch} from 'react-redux';

import HocActionPress from '../HocActionPress';

const styles = StyleSheet.create({
  main: {
    backgroundColor: '#F2F2F2',
    minHeight: 35,
    borderRadius: 10,
    shadowColor: '#171717',
    shadowOffset: {width: 4, height: 4},
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
});

const stylesHtml = {
  select: {
    padding: 10,

    borderRadius: 8,
    fontSize: 15,
    flex: 1,
    backgroundColor: '#F2F2F2',

    fontWeight: '600',
    borderWidth: 0,
    borderColor: 'white',
  },
  option: {
    padding: 10,
  },
};

function Select({onChange, value, options = [], sagaOnChange, items = []}) {
  const dispatch = useDispatch();
  const values = [...options, ...items];

  const onPress = useCallback(
    item => {
      onChange(item);
      if (sagaOnChange) {
        dispatch({
          type: sagaOnChange,
          payload: item,
        });
      }
    },
    [onChange, dispatch, sagaOnChange],
  );

  return (
    <View style={styles.main}>
      <select
        style={stylesHtml.select}
        value={value}
        onChange={e => onPress(e.target.value)}>
        <option value="">Seleziona</option>
        {values.map(op => (
          <option key={op.value || op} value={op.value || op}>
            {op.title || op.value || op}
          </option>
        ))}
      </select>
    </View>
  );
}

export default React.memo(HocActionPress(Select));
