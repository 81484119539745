import React from 'react';
import {StyleSheet, View} from 'react-native';
import HocActionPress from '../HocActionPress';

import Button from '../Button';
import Icon from '../Icon';
import Text from '../Text';

const styles = StyleSheet.create({
  main: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  text: {
    marginVertical: 20,
    fontWeight: '600',
    textAlign: 'center',
  },
});

function Result({
  value,
  text = '',
  onPress,
  titleButton = '',
  icon,
  colorIcon,
}) {
  return (
    <View style={styles.main}>
      <Icon name={icon} color={colorIcon} size={60} />
      <Text variant="h4" style={styles.text} text={text} />
      {value && <Text variant="h5" style={styles.text} text={value} />}
      <Button
        size="small"
        onPress={onPress}
        bold
        variant="primary"
        title={titleButton}
      />
    </View>
  );
}

export default React.memo(HocActionPress(Result));
