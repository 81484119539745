import React from 'react';
import {ScrollView, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  main: {
    borderRadius: 10,
    marginHorizontal: 20,
  },
  content: {flex: 1},
  white: {
    backgroundColor: 'white',
  },
});

export default function MyScrollview({
  children,
  paddingHorizontal,
  paddingVertical,
  background,
  keyboardShouldPersistTaps,
}) {
  return (
    <ScrollView
      style={[styles.main, background && styles[background]]}
      keyboardShouldPersistTaps={keyboardShouldPersistTaps ? 'always' : 'never'}
      contentContainerStyle={[
        styles.content,
        paddingHorizontal && {paddingHorizontal: 20},
        paddingVertical && {paddingVertical: 20},
        background && styles[background],
      ]}
      showsVerticalScrollIndicator={false}>
      {children}
    </ScrollView>
  );
}
