import React from 'react';
import {FlatList, View, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  list: {
    flex: 1,
  },
});

export const getKey = (item, primaryKey) =>
  (item[primaryKey] && item[primaryKey].toString()) || item.value || item.name;

function List({
  items,
  renderItem,
  contentContainerStyle,
  primaryKey = '_id',
  emptyState,
  flat = true,
  columns,
  style,
  ...props
}) {
  if (!items.length) {
    return emptyState || null;
  }

  return flat ? (
    <FlatList
      data={items}
      style={style}
      renderItem={renderItem}
      contentContainerStyle={contentContainerStyle}
      keyExtractor={item => getKey(item, primaryKey)}
      numColumns={columns}
      {...props}
    />
  ) : (
    <View style={[styles.list, style]}>
      {items.map((item, index) => (
        <View key={getKey(item, primaryKey) + index}>
          {renderItem({item, index})}
        </View>
      ))}
    </View>
  );
}

export default React.memo(List);
