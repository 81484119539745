import React from 'react';
import {ActivityIndicator, StyleSheet, View} from 'react-native';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
});

function Loading() {
  return (
    <View style={styles.container}>
      <ActivityIndicator />
    </View>
  );
}
export default Loading;
