import React from 'react';
import {StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';
import {getCartTotal} from '../../../containers/Cart/selectors';
import Text from '../../Text';

const styles = StyleSheet.create({
  main: {color: '#000', fontWeight: '700'},
});

function CartTotal({fontSize = '18'}) {
  const total = useSelector(getCartTotal);
  return (
    <Text
      style={[styles.main, {fontSize: parseInt(fontSize)}]}
      format="price"
      text={total}
    />
  );
}

export default React.memo(CartTotal);
