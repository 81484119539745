import React, {useCallback} from 'react';
import {View, StyleSheet, TextInput} from 'react-native';

const styles = StyleSheet.create({
  main: {
    backgroundColor: '#F2F2F2',
    borderRadius: 10,
    padding: 20,
    shadowColor: '#171717',
    shadowOffset: {width: 4, height: 4},
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  small: {width: '30%'},
  medium: {width: '70%'},
  full: {width: '100%'},
  primary: {
    color: '#000000',
  },
  bold: {
    fontWeight: '600',
  },
});

const Input = ({
  onChange,
  fontSize,
  bold,
  color = 'primary',
  size = 'full',
  value = '',
  onlyNumbers,
}) => {
  const validate = useCallback(
    value => {
      return onlyNumbers ? value.replace(/[^0-9]/g, '') : value;
    },
    [onlyNumbers],
  );

  const handleChangeText = useCallback(
    value => {
      onChange(validate(value));
    },
    [onChange, validate],
  );

  return (
    <View style={[styles.main, size && styles[size]]}>
      <TextInput
        style={[
          fontSize && {fontSize: parseInt(fontSize)},
          bold && styles.bold,
          color && styles[color],
        ]}
        onChangeText={value => handleChangeText(value)}
        value={value}
        keyboardType={onlyNumbers ? 'numeric' : 'default'}
      />
    </View>
  );
};

export default React.memo(Input);
