import React, {useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {FlowerContext} from '@stackhouseos/flower-client';
import {useParams} from '@reach/router';
import Loading from '../Loading';

const Saga = React.memo(({name, value}) => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const {flowName, reducerName} = useContext(FlowerContext);

  useEffect(() => {
    if (name) {
      dispatch({
        type: name,
        payload: {
          flowName,
          reducerName,
          value,
          routeParams,
        },
      });
    }
  }, [dispatch, name, flowName, reducerName, value, routeParams]);

  return <Loading />;
});

export default Saga;
