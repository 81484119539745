import {createSlice, nanoid} from '@reduxjs/toolkit';
import set from 'lodash/set';
import _findIndex from 'lodash/findIndex';
const initialState = {
  items: [],
  total: 0,
};

const sumTotal = items =>
  items.reduce((acc, inc) => acc + inc.price * inc.qty, 0);

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    add: (state, {payload}) => {
      const {price, _id, image, name} = payload.product;
      const index = _findIndex(state.items, {dishId: _id});
      if (state.items.length > 29) {
        // eslint-disable-next-line no-alert
        alert('Attenzione non puoi più aggiungere altri prodotti al carello');
        return;
      }
      if (index > -1) {
        set(state.items, [index, 'qty'], state.items[index].qty + 1);
      } else {
        state.items.push({
          _id: nanoid(),
          dishId: _id,
          price,
          image,
          name,
          qty: 1,
        });
      }

      state.total = sumTotal(state.items);
    },
    increment: (state, {payload}) => {
      const index = _findIndex(state.items, {_id: payload.value});
      if (index > -1 && state.items[index].qty < 20) {
        set(state.items, [index, 'qty'], state.items[index].qty + 1);
      }
      state.total = sumTotal(state.items);
    },
    decrement: (state, {payload}) => {
      const index = _findIndex(state.items, {_id: payload.value});
      if (index > -1) {
        set(state.items, [index, 'qty'], state.items[index].qty - 1);
      }
      // clean list
      state.items = state.items.filter(e => e.qty);
      state.total = sumTotal(state.items);
    },
  },
  extraReducers: {
    '@flower/cart/update': (state, action) =>
      set(state, action.payload.id, action.payload.value),
  },
});

export const actions = slice.actions;
export default slice.reducer;
