import React from 'react';
import {StyleSheet, Image} from 'react-native';

const styles = StyleSheet.create({
  image: {
    alignSelf: 'center',
  },
});

export default function ImageContent({
  value,
  url,
  cover,
  width,
  style,
  height,
  paddingValue = 5,
  paddingHorizontal,
  paddingVertical,
}) {
  return (
    <Image
      resizeMode={cover ? 'cover' : 'contain'}
      style={[
        styles.image,
        style,
        width && {width: parseInt(width)},
        height && {height: parseInt(height)},
        paddingHorizontal && {marginHorizontal: parseInt(paddingValue)},
        paddingVertical && {marginVertical: parseInt(paddingValue)},
      ]}
      source={{uri: value || url}}
    />
  );
}
