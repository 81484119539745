import React from 'react';
import {StyleSheet, Text as NativeText} from 'react-native';

const formatter = {
  default: val => val,
  price: total => parseFloat(total || '0').toFixed(2) + ' €',
};

const styles = StyleSheet.create({
  h1: {
    fontSize: 55,
  },
  h2: {
    fontSize: 45,
  },
  h3: {
    fontSize: 35,
  },
  h4: {
    fontSize: 25,
  },
  h5: {
    fontSize: 20,
    color: 'white',
  },
  center: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: '600',
  },
  gutterBottom: {
    marginBottom: 10,
  },
});

const Text = ({
  text,
  children,
  value,
  numberOfLines,
  gutterBottom,
  bold,
  center,
  variant,
  fontSize,
  style,
  format = 'default',
}) => {
  return (
    <NativeText
      style={[
        style,
        variant && styles[variant],
        bold && styles.bold,
        center && styles.center,
        gutterBottom && styles.gutterBottom,
        fontSize && {fontSize: parseInt(fontSize)},
      ]}
      numberOfLines={numberOfLines}>
      {formatter[format](value || children || text || '')}
    </NativeText>
  );
};

export default React.memo(Text);
