import {createSelector} from 'reselect';
import _get from 'lodash/get';
import _find from 'lodash/find';

const appSelect = state => state.app;
const cartSelect = state => state.cart;
const selectItems = state => state.cart.items;

export const getCartTotal = createSelector(selectItems, items => {
  return items.reduce((acc, inc) => acc + inc.price * inc.qty, 0);
});

export const getCartProductQty = dishId =>
  createSelector(selectItems, items => {
    return items
      .filter(prod => prod.dishId === dishId)
      .reduce((acc, inc) => acc + inc.qty, 0);
  });

export const getCartProduct = dishId =>
  createSelector(selectItems, items => {
    return items
      .filter(prod => prod.dishId === dishId)
      .reduce((acc, inc) => inc, {});
  });
