import React from 'react';
import IconButton from '../IconButton';
import {StyleSheet} from 'react-native';
import View from '../View';
import Flex from '../Flex';
import Text from '../Text';

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: 'transparent',
  },
  back: {
    position: 'absolute',
    left: 0,
  },
  rightElement: {
    position: 'absolute',
    right: 13,
  },
  text: {
    color: 'white',
    fontSize: 16,
  },
});

function BackButton({title = '', right_container, ...props}) {
  return (
    <Flex paddingVertical gutterBottom style={styles.main}>
      <View style={styles.back}>
        <IconButton
          {...props}
          style={styles.icon}
          size={50}
          icon="chevron-left"
          actionType={'pop'}
        />
      </View>
      <Text text={title} style={styles.text} />
      <View style={styles.rightElement}>{right_container || null}</View>
    </Flex>
  );
}

export default React.memo(BackButton);
