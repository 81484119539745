const getNArray = n => Array.from({length: n}, (_, index) => index + 1);

export const sectorConfig = {
  B: {
    IA: {name: 'Inferiore A', lines: getNArray(7), seats: getNArray(20)},
    IB: {name: 'Inferiore B', lines: getNArray(7), seats: getNArray(18)},
    IC: {name: 'Inferiore C', lines: getNArray(7), seats: getNArray(20)},
    II: {name: 'Inferiore I', lines: getNArray(7), seats: getNArray(20)},
    IL: {name: 'Inferiore L', lines: getNArray(7), seats: getNArray(18)},
    IM: {name: 'Inferiore M', lines: getNArray(7), seats: getNArray(20)},
    SA: {name: 'Superiore A', lines: getNArray(10), seats: getNArray(13)},
    SB: {
      name: 'Superiore B',
      lines: [5, 6, 7, 8, 9, 10],
      seats: getNArray(12),
    },
    SC: {
      name: 'Superiore C',
      lines: getNArray(11),
      seats: getNArray(30),
    },
    SI: {name: 'Superiore I', lines: getNArray(11), seats: getNArray(30)},
    SL: {
      name: 'Superiore L',
      lines: [5, 6, 7, 8, 9, 10, 11],
      seats: getNArray(8),
    },
    SM: {name: 'Superiore M', lines: getNArray(11), seats: getNArray(17)},
  },
  R: {
    ID: {name: 'Inferiore D', lines: getNArray(7), seats: getNArray(25)},
    IE: {name: 'Inferiore E', lines: getNArray(7), seats: getNArray(13)},
    IG: {name: 'Inferiore G', lines: getNArray(7), seats: getNArray(13)},
    IH: {name: 'Inferiore H', lines: getNArray(7), seats: getNArray(25)},
    SE: {name: 'Superiore E', lines: getNArray(11), seats: getNArray(29)},
    SG: {name: 'Superiore G', lines: getNArray(11), seats: getNArray(29)},
    SH: {
      name: 'Superiore H',
      lines: [5, 6, 7, 8, 9, 10, 11],
      seats: getNArray(16),
    },
  },
  other: {
    'I-TRB-VIP': {
      name: "Tribuna D'Onore",
      lines: [5, 6, 7, 8, 9, 10, 11],
      seats: getNArray(10),
    },
    'S-TRB-VIP': {
      name: 'Tribuna VIP Superiore ',
      lines: [5, 6, 7, 8, 9, 10],
      seats: getNArray(10),
    },
    'TRB-S': {
      name: 'Tribuna Stampa',
      lines: [5, 6, 7, 8, 9, 10],
      seats: getNArray(10),
    },
  },
};
