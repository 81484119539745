import React, {useCallback} from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import {useDispatch} from 'react-redux';
import List from '../List';

const styles = StyleSheet.create({
  main: {},
  container: {},
  clea: {},
  content: {
    backgroundColor: 'white',
    borderRadius: 10,
    marginHorizontal: 20,
  },
});

const Item = ({renderChildren, fields, prefix, onPress, columns}) => {
  const Comp = onPress ? TouchableOpacity : View;
  const {width} = useWindowDimensions();
  return (
    <Comp style={{width: (width - 40) / columns}} onPress={onPress}>
      {renderChildren(fields, prefix)}
    </Comp>
  );
};

export default function FlatList({
  childId,
  value = [],
  item_container_children,
  emptyState_container,
  header_container,
  footer_container,
  renderChildren,
  actionOnPress,
  flat,
  columns,
  variant,
}) {
  const dispatch = useDispatch();

  const onPress = useCallback(
    _id => {
      if (actionOnPress) {
        dispatch({type: actionOnPress, payload: {_id}});
      }
    },
    [actionOnPress, dispatch],
  );

  const renderItem = ({item, index}) => (
    <Item
      fields={item_container_children}
      prefix={`${childId}.${index}`}
      renderChildren={renderChildren}
      onPress={actionOnPress && (() => onPress(item._id))}
      columns={columns}
    />
  );

  return (
    <List
      renderItem={renderItem}
      items={value}
      flat={flat}
      style={[styles.main, styles[variant]]}
      columns={columns}
      ListHeaderComponent={<>{header_container}</>}
      ListFooterComponent={<>{footer_container}</>}
      emptyState={emptyState_container}
      contentContainerStyle={styles.container}
    />
  );
}
