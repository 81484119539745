import React from 'react';
import {View, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  main: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrap: {
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  child: {
    minWidth: '50%',
  },
  white: {
    backgroundColor: 'white',
  },
  column: {
    flexDirection: 'column',
  },
});

const renderChildren = children => {
  return React.Children.map(children, child => (
    <View style={styles.child}>{child}</View>
  ));
};

export default function Flex({
  children,
  style,
  wrap,
  paddingHorizontal,
  paddingVertical,
  background,
  grid,
  column,
  center,
  justify = 'space-between',
}) {
  return (
    <View
      style={[
        styles.main,
        (wrap || grid) && styles.wrap,
        background && styles[background],
        column && styles.column,
        paddingHorizontal && {paddingHorizontal: 20},
        paddingVertical && {paddingVertical: 20},
        justify && {justifyContent: justify},
        style,
      ]}>
      {grid ? renderChildren(children) : children}
    </View>
  );
}
