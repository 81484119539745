import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {getCartProductQty} from '../../../containers/Cart/selectors';
import Spacer from '../../Spacer';
import Text from '../../Text';

const styles = StyleSheet.create({
  main: {flexDirection: 'row'},
});

function CartProductQty({value}) {
  const total = useSelector(getCartProductQty(value));

  return total ? (
    <View style={styles.main}>
      <Text text="Qty: " /> <Text bold text={total} />
    </View>
  ) : (
    <Spacer size="xxsmall" />
  );
}

export default React.memo(CartProductQty);
